img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.project-display {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  outline: 1px solid var(--secondary-color);
  border-radius: 10px;
  padding: 1rem;
}

.project-display h1 {
  color: var(--secondary-color);
  font-family: var(--font-mono);
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: left;
}
