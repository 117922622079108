:root {
  --primary-color: #171c28;
  --secondary-color: #dda448;
  --tertiary-color: #6b818c;
  --text-color: #fbfaf8;

  --font-sans: sans-serif;
  --font-mono: monospace;
}

.body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  scrollbar-color: var(--secondary-color) var(--primary-color);
  background-color: var(--primary-color);
}

.content {
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--text-color);
  padding-top: 10rem;
}

.side-display-left {
  height: 100vh;
  width: 40px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
}

.side-display-left-icon {
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--tertiary-color);
  transition: all 0.2s ease-in;
}

.side-display-left-icon:hover {
  transform: translate(0, -5px);
}

.side-display-left a {
  color: var(--tertiary-color);
}

.side-display-left a:hover {
  color: var(--secondary-color);
}

.side-display-right {
  height: 100vh;
  width: 40px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  writing-mode: vertical-rl;
  padding-right: 40px;
}

.side-display-right a {
  margin-left: 5px;
  padding: 1rem;
  letter-spacing: 0.1rem;
  writing-mode: vertical-rl;
  text-decoration: none;
  font: 1.25rem var(--font-mono);
  color: var(--tertiary-color);
  transition: all 0.2s ease-in-out;
}

.side-display-right a:hover {
  color: var(--secondary-color);
  transform: translate(0, -5px);
}

#hyperlink {
  color: var(--secondary-color);
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

#hyperlink:hover {
  font-size: 1.55rem;
}

.vertical-line-top {
  height: 100%;
  width: 1px;
}

.vertical-line-bottom {
  height: 10%;
  width: 1px;
  background-color: var(--tertiary-color);
}

.vertical-line-bottom-right {
  height: 20%;
  width: 1px;
  background-color: var(--tertiary-color);
}

#contact-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#contact-icon {
  color: var(--tertiary-color);
  font-size: 2.5rem;
  transition: all 0.2s ease-in;
  padding: 0;
  margin: 0;

}

#contact-icon:hover {
  color: var(--secondary-color);
  transform: translate(0, -5px);
  transition: all 0.2s ease-in;
}

/* Mobile Styles */

/* Hide side displays on narrow screens */
@media screen and (max-width: 767px) {
  .side-display-left,
  .side-display-right {
    display: none;
  }

  .content {
    padding-top: 5rem;
  }
}
