/* filepath: /src/components/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: var(--primary-color);
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    max-height: 50%;
    overflow-y: auto;
  }
  
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .image-gallery img {
    max-width: 75%;
    height: auto;
    border-radius: 4px;
  }